import Layout from "../Layout/Layout.component";
import {AppContext} from "../Context/MainContext";
import {useContext, useEffect, useState} from "react";
import {getSpendsByPaymentStatus, getSpendsByStatus} from "../utils/transactions.util";
import {ResponsivePie} from "@nivo/pie";
import {formatCurrency} from "../utils/number.utils";
import {keyboard} from "@testing-library/user-event/dist/keyboard";

const SpendsDashboardPage = () => {

    const { transactions} = useContext(AppContext);
    const [data, setData] = useState<any>({});
    const [chartData, setChartData] = useState<any>({})
    const [reducer, setReducer] = useState([getSpendsByPaymentStatus, getSpendsByStatus]);
    const [drillDownLevel, setDrillDownLevel] = useState<number>(1)

    useEffect(() => {
        if (transactions.length > 0) {
            let datum: any = {}
            let chartDatum : any = []

            let spendsReduced: any = reducer[0](transactions.filter((tr: any) => tr.type === 'FACTURA'));
            Object.keys(spendsReduced).forEach((key: string) => {
                chartDatum.push({
                    id: key,
                    label: key,
                    value: spendsReduced[key].reduce((acc: number, transaction: any) => acc + transaction.total, 0)
                })
                datum[key] = reducer[1](spendsReduced[key])
            });
            setData(datum)
            setChartData(chartDatum)
        }
    }, [transactions, reducer]);

    useEffect(() => {
        if (drillDownLevel === 1) {
            let chartDatum : any = []
            let spendsReduced: any = reducer[0](transactions.filter((tr: any) => tr.type === 'FACTURA'));
            Object.keys(spendsReduced).forEach((key: string) => {
                chartDatum.push({
                    id: key,
                    label: key,
                    value: spendsReduced[key].reduce((acc: number, transaction: any) => acc + transaction.total, 0)
                })
            });
            setChartData(chartDatum)
        }
    }, [drillDownLevel]);

    const drillDown = (datum: any) => {
        if (drillDownLevel === 2) {
            return;
        }
        setDrillDownLevel(drillDownLevel + 1)
        let transactions = data[datum.id]
        let chartDatum : any = []
        Object.keys(transactions).forEach((key: string) => {
            chartDatum.push({
                id: key,
                label: key,
                value: transactions[key].reduce((acc: number, transaction: any) => acc + transaction.total, 0)
            })
        });
        setChartData(chartDatum)
    }

    return (
        <Layout>
            <div className="container">
                <h1 className="text-2xl font-bold mb-4">Dashboard de pagos</h1>
            </div>
            <div className="flex">
                <div className="" style={{width: "50%"}}>
                    <h2 className="text-2xl font-bold my-4">Resumen</h2>
                    <table className="ml-20 mt-6">
                        <thead className="sticky top-0 bg-blue-500 text-white">
                        <tr className="text-sm border border-3">
                            <th>Estado</th>
                            <th>Estado Pago</th>
                            <th># Documentos</th>
                            <th>Total $</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data && Object.keys(data).map((status) => {
                            let total = Object.keys(data[status]).reduce((acc: number, subStatus: any) => acc + data[status][subStatus].reduce((acc: number, transaction: any) => acc + transaction.total, 0), 0)
                            let count = Object.keys(data[status]).reduce((acc: number, subStatus: any) => acc + data[status][subStatus].length, 0)
                            return (<>
                                <tr
                                    key={status}
                                    className="text-xs border border-slate-500 bg-slate-300"
                                >
                                    <td className="border border-slate-500 bg-slate-300">
                                        {status}
                                    </td>
                                    <td></td>
                                    <td className="border border-slate-500 text-center">
                                        {count}
                                    </td>
                                    <td className="border border-slate-500">
                                        ${formatCurrency(total)}
                                    </td>
                                </tr>
                                {Object.keys(data[status]).map((subStatus) => {
                                    let total = data[status][subStatus].reduce((acc: number, transaction: any) => acc + transaction.total, 0)
                                    let count = data[status][subStatus].length
                                    return (<tr
                                            key={subStatus}
                                            className="text-xs border border-slate-500 "
                                        >
                                            <td></td>
                                            <td className="borde border-slate-500">{subStatus}</td>
                                            <td className="borde border-slate-500 text-center">
                                                {count}
                                            </td>
                                            <td className="borde border-slate-500">
                                                ${formatCurrency(total)}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </>
                        )})}
                        </tbody>
                    </table>

                </div>
                <div style={{height: "500px", width: "50%"}}>
                    <div className="flex justify-start gap-2 ">
                        {reducer[0] !== getSpendsByStatus &&
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    onClick={() => setReducer([getSpendsByStatus, getSpendsByPaymentStatus])}>
                                Por estado
                            </button>}
                        {reducer[0] !== getSpendsByPaymentStatus &&
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    onClick={() => setReducer([getSpendsByPaymentStatus, getSpendsByStatus])}>
                                Por tipo de pago
                            </button>}
                    </div>
                    <div className="mt-4">
                        {drillDownLevel > 1 &&
                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                                    onClick={() => {
                                        setDrillDownLevel(drillDownLevel - 1)
                                        setChartData(chartData)
                                    }}>
                                Volver
                            </button>}
                    </div>

                    <ResponsivePie
                        data={chartData}
                        margin={{top: 40, right: 80, bottom: 80, left: 120}}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        colors={{ scheme: 'nivo' }}
                        borderWidth={1}
                        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                        valueFormat={(value: number) => `$${formatCurrency(value)}`}
                        onClick={drillDown}

                    />
                </div>
            </div>
        </Layout>
);
}

export default SpendsDashboardPage;