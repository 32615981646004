import Layout from "../Layout/Layout.component";
import {formatCurrency} from "../utils/number.utils";
import {AppContext} from "../Context/MainContext";
import {useContext, useEffect, useState} from "react";
import InfoCard from "../Components/InfoCard.component";

const WorkloadDashboardPage = () => {

    const { selectedProject, planifications } = useContext(AppContext);
    const [data, setData] = useState<Map<string, number>>(new Map());

    useEffect(() => {
        if (selectedProject && planifications.length > 0) {
            let workload = planifications.reduce((acc: Map<string, number>, pl: any) => {
                pl.workloadValues.forEach((wl: any) => {
                    if (!Object.keys(acc).includes(wl.name)) {
                        acc.set(wl.name, wl.budget);
                    } else {
                        acc.set(wl.name, acc.get(wl.name) + wl.budget);
                    }
                });
                return acc;
            }, new Map());

            setData(workload);
        }
    }, [planifications, selectedProject]);

    return (
        <Layout>
            <div className="container">
                <h1 className="text-2xl
                    font-bold mb-4">Dashboard Mano de Obra</h1>
                <div className="info-cards">
                    <div>
                        <h2 className="text-xl font-bold my-4">Resumen financiero</h2>
                        <div className="info-card flex gap-2">
                            { selectedProject && <InfoCard
                                key="total"
                                title="Total"
                                content={[
                                    `Presupuesto: $ ${formatCurrency(
                                        selectedProject.workload
                                            .reduce((acc: number, wk: {budget: number}) => acc + wk.budget, 0))}`,
                                    `Gastado: $ ${formatCurrency(Array.from(data.values()).reduce((acc: number, val: number) => acc + val, 0))}`,
                                    `Restante: $ ${formatCurrency(( selectedProject.workload
                                        .reduce((acc: number, wk: {budget: number}) => acc + wk.budget, 0) - (Array.from(data.values()).reduce((acc: number, val: number) => acc + val, 0)) ))}`,
                                ]}
                            />}
                            {selectedProject && selectedProject.workload.map((wk: {name: string, budget: number}, index: number) => (
                                <InfoCard
                                    key={index}
                                    title={wk.name}
                                    content={[
                                        `Presupuesto: $ ${formatCurrency(wk.budget)}`,
                                        `Gastado: $ ${formatCurrency(data.get(wk.name) || 0)}`,
                                        `Restante: $ ${formatCurrency((wk.budget - (data.get(wk.name) || 0)))}`,
                                    ]}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default WorkloadDashboardPage;