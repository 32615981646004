export const getSpendsByStatus = (transactions: any[]) => {
    let res : any = {};
    transactions.forEach((transaction: any) => {
        let status = transaction.status || "N/A"
        if (!Object.keys(res).includes(status)) {
            res[status] = [transaction];
        } else {
            res[status].push(transaction);
        }
    });
    return res;
}

export const getSpendsByPaymentStatus = (transactions: any[]) => {
    let res : any = {};
    transactions.forEach((transaction: any) => {
        let status = transaction.paymentStatus || "N/A"
        if (!Object.keys(res).includes(status)) {
            res[status] = [transaction];
        } else {
            res[status].push(transaction);
        }
    });
    return res;
}