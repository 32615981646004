import Layout from "../Layout/Layout.component";
import {AppContext} from "../Context/MainContext";
import {useContext, useEffect, useState} from "react";
import {getProjectResume} from "../utils/planification.utils";
import DataTable from "react-data-table-component";
import {formatCurrency} from "../utils/number.utils";

const PlanificationDetailPage = () => {

    /*
    __v: 0
    _id: "674e6efcd13294157a992e87"
    accCostValue: 0
    accEEPP: 0
    accEarnValue: 0
    accPlanifiedEarnValue: 5564006
    costValue: 0
    createdAt: "2024-12-03T02:37:48.935Z"
    earnValue: 0
    endDate: "2024-09-16T02:59:59.999Z"
    index: 1
    planifiedEarnValue: 5564006
    planifiedEarnValuePercentage: 0.11
    project: "674e6efcd13294157a992e7c"
    startDate: "2024-09-09T03:00:00.000Z"
    updatedAt: "2024-12-03T02:37:48.935Z"
     */
    const { transactions, planifications } = useContext(AppContext);
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        if (planifications.length > 0) {
            let datum = getProjectResume(planifications, transactions);
            setData(datum);
        }
    }, [planifications, transactions]);

    let cols = [
        { width: "7%" ,name: "Semana", selector: (row: any) => row.index, sortable: true },
        { name: "Fecha de inicio", selector: (row: any) => new Date(row.startDate).toLocaleDateString(), sortable: true, sortFunction: (a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime() },
        { name: "Fecha de fin", selector: (row: any) => new Date(row.endDate).toLocaleDateString(), sortable: true, sortFunction: (a: any, b: any) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime() },
        { name: "Valor planificado", selector: (row: any) => `$${formatCurrency(row.planifiedEarnValue)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.planifiedEarnValue) - parseInt(b.planifiedEarnValue) },
        { name: "Valor planificado acumulado", selector: (row: any) => `$${formatCurrency(row.accPlanifiedEarnValue)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.accPlanifiedEarnValue) - parseInt(b.accPlanifiedEarnValue) },
        { name: "Valor ganado", selector: (row: any) => `$${formatCurrency(row.earnValue)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.earnValue) - parseInt(b.earnValue) },
        { name: "Valor ganado acumulado", selector: (row: any) => `$${formatCurrency(row.accEarnValue)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.accEarnValue) - parseInt(b.accEarnValue) },
        { name: "Costo", selector: (row: any) => `$${formatCurrency(row.costValue)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.costValue) - parseInt(b.costValue) },
        { name: "Costo acumulado", selector: (row: any) => `$${formatCurrency(row.accCostValue)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.accCostValue) - parseInt(b.accCostValue) },
        { name: "EEPP", selector: (row: any) => `$${formatCurrency(row.EEPP)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.accEEPP) - parseInt(b.accEEPP) },
        { name: "EEPP acumulado", selector: (row: any) => `$${formatCurrency(row.accEEPP)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.accEEPP) - parseInt(b.accEEPP)}
    ];

    return (<Layout>
        <div className="container">
            <h1 className="text-2xl font-bold mb-4">Detalle de planificación</h1>
            <DataTable
                columns={cols}
                data={data}
                pagination={true}
                defaultSortFieldId={1}
                paginationComponentOptions={{
                    rowsPerPageText: 'Filas por página',
                    rangeSeparatorText: 'de',
                    selectAllRowsItem: true,
                    selectAllRowsItemText: 'Todos',
                }}
            />
        </div>
    </Layout>);
}

export default PlanificationDetailPage;