import React, {useContext, useState} from 'react';
import { FaHouseChimney, FaFileInvoiceDollar, FaPeopleGroup } from 'react-icons/fa6';
import { FaChartPie, FaRegChartBar } from 'react-icons/fa';
import {useNavigate} from "react-router-dom";
import {AppContext} from "../Context/MainContext";

interface SidebarProps {
    sidebarOpen: boolean;
    setSidebarOpen: (value: boolean) => void;
}

interface IItem {
    title: string;
    icon: JSX.Element;
    link: string;
    subMenu: IItem[];
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
    // Sidebar navigation links
    const {menuSelected, setMenuSelected} = useContext(AppContext);

    const menuItems: IItem[] = [
        {
            title: 'Panel Central',
            icon: <FaHouseChimney className="w-6" height={24} />,
            link: '/dashboard',
            subMenu: [],
        },{
            title: 'Documentos de pago',
            icon: <FaFileInvoiceDollar />,
            link: '',
            subMenu: [{
                title: "Dashboard",
                icon: <FaFileInvoiceDollar />,
                link: '/dashboard-pagos',
                subMenu: [],
            },{
                title: 'Ordenes de Compra',
                icon: <FaFileInvoiceDollar />,
                link: '/ordenes-compra',
                subMenu: [],
                },
                {
                    title: 'Facturas',
                    icon: <FaFileInvoiceDollar />,
                    link: '/facturas',
                    subMenu: [],
                },
                {
                    title: 'Notas de crédito',
                    icon: <FaFileInvoiceDollar />,
                    link: '/notas-credito',
                    subMenu: [],
                }]
        },
        {
            title: 'Hojas de Control',
            icon: <FaRegChartBar />,
            link: '/hojas-control',
            subMenu: [],
        },
        {
            title: 'Control de Mano de Obra',
            icon: <FaPeopleGroup />,
            link: '',
            subMenu: [
                {
                    title: 'Dashboard',
                    icon: <FaPeopleGroup />,
                    link: '/mano-obra/dahboard',
                    subMenu: [],
                },{
                    title: 'Reporte',
                    icon: <FaPeopleGroup />,
                    link: '/mano-obra/reporte',
                    subMenu: [],
                }
            ],
        },
        {
            title: 'Informe Gerencial',
            icon: <FaChartPie />,
            link: '',
            subMenu: [{
                title: 'Valor Ganado',
                icon: <FaChartPie />,
                link: '/informe-gerencial',
                subMenu: [],
            }, {
                title: 'Planificación',
                icon: <FaChartPie />,
                link: '/planificacion',
                subMenu: [],
            }],
        },
    ];

    const navigate = useNavigate();

    const [selected, setSelected] = useState(menuItems[0].title);
    const [openSubMenu, setOpenSubMenu] = useState<string | null>(null);

    const handleItemClick = (item: any) => {
        setMenuSelected(item.title);
        if (item.subMenu.length > 0) {
            setOpenSubMenu(openSubMenu === item.title ? null : item.title); // Toggle submenus
        } else {
            navigate(item.link)
        }
    };

    return (
        <div
            className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition-transform duration-300 transform bg-gray-900 lg:translate-x-0 lg:static lg:inset-0 ${
                sidebarOpen ? 'translate-x-0' : '-translate-x-full'
            }`}
        >
            <div className="flex items-center justify-center mt-8">
                <div className="flex items-center">
                    <img src="/Syncroniza_transparente.png" alt="Logo" className="w-24 h-24" />
                </div>
            </div>

            <nav className="mt-10">
                {/* Sidebar links */}
                {menuItems.map((item, index) => (
                    <div key={index}>
                        <div
                            className={`flex items-center px-8 py-2 text-gray-100 border-r-4 cursor-pointer ${
                                menuSelected === item.title ? 'bg-gray-800 border-indigo-600' : 'border-gray-900'
                            }`}
                            onClick={() => handleItemClick(item)}
                        >
                            <span className="mx-2">{item.icon}</span>
                            <span className="mx-4 font-medium">{item.title}</span>
                        </div>

                        {/* Show submenu if selected */}
                        {item.subMenu.length > 0 && menuSelected === item.title && (
                            <div className="ml-6 mt-2">
                                {item.subMenu.map((subItem, subIndex) => (
                                    <div
                                        key={subIndex}
                                        className={`flex items-center px-8 py-2 text-gray-100 border-r-4 cursor-pointer ${
                                            selected === subItem.title ? 'bg-gray-800 border-indigo-600' : 'border-gray-900'
                                        }`}
                                        onClick={() => handleItemClick(subItem)}
                                    >
                                        <span className="mx-4">{subItem.icon}</span>
                                        <span className="mx-4 font-medium">{subItem.title}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                ))}
            </nav>
        </div>
    );
};

export default Sidebar;