export const getProjectResume = (planifications: any[], transactions: any[]) => {
    let accSpends = 0;
    let accEEPP = 0;
    let accEarnValue = 0;
    let accPlanifiedEarnValue = 0;

    let datum = planifications
        .sort((a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
        .map((planification: any, index: number) => {

            let startDate = new Date(planification.startDate);
            let endDate = new Date(planification.endDate);

            let transactionsInPeriod = transactions.filter((transaction: any) => {
                let transactionDate = new Date(transaction.date);
                return transactionDate >= startDate && transactionDate <= endDate;
            });

            let invoiceTotal = transactionsInPeriod
                .filter((transaction: any) => transaction.type === 'FACTURA')
                .filter((transaction: any) => transaction.status.toLowerCase().includes('aprobada'))
                .reduce((acc: number, transaction: any) => acc + transaction.total, 0);

            let EEPP = transactionsInPeriod
                .filter((transaction: any) => transaction.type === 'EEPP')
                .reduce((acc: number, transaction: any) => acc + transaction.total, 0);

            let costValue = invoiceTotal + planification.workloadValues.reduce((acc: number, wl: any) => acc + wl.value, 0);
            accSpends += costValue;
            accEEPP += EEPP;
            accEarnValue += planification.earnValue;
            accPlanifiedEarnValue += planification.planifiedEarnValue;

            planification.accPlanifiedEarnValue = accPlanifiedEarnValue;
            planification.accEarnValue = accEarnValue;
            planification.costValue = costValue;
            planification.accCostValue = accSpends;
            planification.accEEPP = accEEPP;
            planification.EEPP = EEPP;
            planification.index = index + 1;

            return planification;
        });
    return datum;
}

export const getSpendsByControlSheet = (transactions: any[], controlSheet: string | null) => {
    let spends = transactions
        .filter((transaction: any) => transaction.type === 'FACTURA' && transaction.status.toLowerCase().includes('aprobada'))
        .filter((transaction: any) => transaction.controlSheet === controlSheet)
        .reduce((acc: any, transaction: any) => {
            acc += transaction.total;
            return acc;
        }, 0);
    return spends;
}

export const getSpendsByFamily = (transactions: any[], family: string | null) => {
    let spends = transactions
        .filter((transaction: any) => transaction.type === 'FACTURA' && transaction.status.toLowerCase().includes('aprobada'))
        .filter((transaction: any) => transaction.family === family)
        .reduce((acc: any, transaction: any) => {
            acc += transaction.total;
            return acc;
        }, 0);
    return spends;
}


export const getSpendsByFamilies = (transactions: any[], project: any) => {
    let res: any = {};
    project.families.map((family: any) => {
        let spends = getSpendsByFamily(transactions, family.name);
        res[family.name] = spends;
    });
    res["Sin familia"] = getSpendsByFamily(transactions, null);

    return res;
}

export const getControlSheetResumes = (transactions: any[], controlSheets: any[]) => {
    let res: any = controlSheets.map((controlSheet: any) => {
        let spends = getSpendsByControlSheet(transactions, controlSheet.name);
        res.push({
            ...controlSheet,
            spends: spends
        });
    });
    return res;
}

