import {useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../Context/MainContext";
import Layout from "../Layout/Layout.component";
import DataTable from "react-data-table-component";
import {formatCurrency} from "../utils/number.utils";
import TextFilterComponent from "../Components/Filtering/TextFilter.component";

const OCsPage = () => {

    const { transactions, selectedProject } = useContext(AppContext);
    const [data, setData] = useState<any[]>([]);
    const [family, setFamily] = useState<any[]>([]);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data.filter(item => item.client && item.client.toLowerCase().includes(filterText.toLowerCase()));
    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };
        return <TextFilterComponent onFilter={value => setFilterText(value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    useEffect(() => {
        if (transactions.length > 0) {
            let tmp = transactions.filter((transaction: any) => transaction.type === 'OC');
            setData(tmp);
        }
    }, [transactions]);

    let columns = [
        { wrap: true, maxWidth: "10%", name: "Fecha", selector: (row: any) => new Date(row.date).toLocaleDateString(), sortable: true, sortFunction: (a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime() },
        { wrap: true, maxWidth: "10%", name: "Familia", selector: (row: any) => row.family, sortable: true },
        { wrap: true, maxWidth: "15%", name: "Hoja de control", selector: (row: any) => row.controlSheet ? row.controlSheet.name : "", sortable: true },
        { wrap: true, maxWidth: "10%", name: "O/C", selector: (row: any) => row.rawValue.cabecera.documento.numOc },
        { wrap: true, maxWidth: "15%", name: "Proveedor", selector: (row: any) => row.client, sortable: true },
        { wrap: true, maxWidth: "20%", name: "Descripción", selector: (row: any) => row.description },
        { wrap: true, maxWidth: "10%", name: "Monto", selector: (row: any) => `$${formatCurrency(row.total)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.total) - parseInt(b.total) },
        { wrap: true, maxWidth: "10%", name: "Estado", selector: (row: any) => row.status },
        ];

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };


    return (<Layout>
        <div className="container">
            <h1 className="text-2xl font-bold mb-4">Ordenes de Compra</h1>
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination={true}
                paginationComponentOptions={paginationComponentOptions}
                defaultSortFieldId={1}
                defaultSortAsc={false}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
            />
        </div>
    </Layout>

    );
}

export default OCsPage;