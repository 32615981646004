import {PAGE_STATUS} from "../interfaces";
import React from "react";

const Loading : React.FC<{status: PAGE_STATUS}> = ({status}) => {
    return status === PAGE_STATUS.LOADING ? (
                <div>
                    <div className="flex justify-center items-center my-12">
                        <div
                            className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
                    </div>
                    <div>
                        <h1 className="text-center text-2xl font-bold">Cargando...</h1>
                    </div>
                </div>
            ) : null
}

export default Loading;