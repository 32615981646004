import React, {useContext, useEffect, useState} from 'react';
import Navbar from './Navbar.component';
import Sidebar from './Sidebar.component';
import {AppContext} from "../Context/MainContext";

interface LayoutProps {
    children: React.ReactNode;
}

const Layout = ({ children } : LayoutProps) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const {user, setUser} = useContext(AppContext);

    useEffect(() => {
        if (!user) {
            let user = JSON.parse(sessionStorage.getItem('user') || '{}');
            setUser(user);
        }
    }, [user]);

    return (
        <div className="flex h-screen bg-gray-200">
            {/* Overlay for small screens */}
            {sidebarOpen && (
                <div
                    className="fixed inset-0 z-20 transition-opacity bg-black opacity-50 lg:hidden"
                    onClick={() => setSidebarOpen(false)}
                />
            )}

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Main content */}
            <div className="flex flex-col flex-1 overflow-hidden">
                <Navbar setSidebarOpen={setSidebarOpen} />

                <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200">
                    <div className="container px-6 py-8 mx-auto">{children}</div>
                </main>
            </div>
        </div>
    );
};

export default Layout;
