import Layout from "../Layout/Layout.component";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../Context/MainContext";
import InfoCard from "../Components/InfoCard.component";
import {formatCurrency} from "../utils/number.utils";
import DataTable from "react-data-table-component";

const ControlSheetPage = () => {

    const [selectedControlSheet, setSelectedControlSheet] = useState<any>(null);
    const [data, setData] = useState<any>(null);
    const {controlSheets, transactions} = useContext(AppContext);

    useEffect(() => {
        if (selectedControlSheet && transactions.length > 0) {
            let invoices = transactions.filter((transaction: any) => transaction.type === 'FACTURA' && transaction.controlSheet && transaction.controlSheet._id === selectedControlSheet._id);
            let OCs = transactions.filter((transaction: any) => transaction.type === 'OC' && transaction.controlSheet && transaction.controlSheet._id === selectedControlSheet._id);
            let NNCCs = transactions.filter((transaction: any) => transaction.type === 'NNCC' && transaction.controlSheet && transaction.controlSheet._id === selectedControlSheet._id);

            let agreements = selectedControlSheet.agreements.reduce((a: number, b: any) => b.amount + a, 0);
            let recoverable = selectedControlSheet.observations.reduce((a: number, b: any) => b.recoverAmount + a, 0);
            let total = selectedControlSheet.budget + recoverable;
            let balance = total - agreements;

            let invoicesTotal = invoices.reduce((a: number, b: any) => b.total + a, 0);
            let paidInvoices = invoices.filter((invoice: any) => invoice.paymentStatus.toLowerCase() === 'pagada').reduce((a: number, b: any) => b.total + a, 0);
            let pendingInvoices = invoicesTotal - paidInvoices;

            let NNCCsTotal = NNCCs.reduce((a: number, b: any) => b.total + a, 0);
            let OCsTotal = OCs.reduce((a: number, b: any) => b.total + a, 0);

            setData({
                agreements,
                recoverable,
                total,
                balance,
                invoicesTotal,
                paidInvoices,
                pendingInvoices,
                NNCCsTotal,
                OCsTotal,
                invoices,
                OCs,
                NNCCs
            });
        }
    }, [selectedControlSheet, transactions]);

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };




    return (
        <Layout>
        <div className="container">
            <h1 className="text-2xl font-bold mb-4">Hojas de control</h1>
            {/*select a control sheet*/}
            <select className="border border-gray-300 rounded-md p-2" onChange={(e) => {
                setSelectedControlSheet(controlSheets.find((controlSheet: any) => controlSheet._id === e.target.value));
            }}>
                <option>Seleccione una hoja de control</option>
                {controlSheets.sort(
                    (a: any, b: any) => a.name > b.name ? 1 : -1
                ).map((controlSheet: any) => <option  key={controlSheet._id} value={controlSheet._id}>{controlSheet.name}</option>)}
            </select>
            {/*display selected control sheet*/}
            {selectedControlSheet && data && <div>
                <h2 className="text-xl font-bold mt-4">Detalle de la hoja de control: {selectedControlSheet.name}</h2>
                <div className="flex gap-2">
                    <InfoCard
                        title="Monto Propuesto"
                        content={[`$${formatCurrency(selectedControlSheet.budget)}`]}
                    />
                    <InfoCard
                        title={"Monto Contrato"}
                        content={[`$${formatCurrency(data.agreements)}`]}
                    />

                    <InfoCard
                        title={"Recuperable"}
                        content={[`$${formatCurrency(data.recoverable)}`]}
                    />

                    <InfoCard
                        title={"Total con extras"}
                        content={[`$${formatCurrency(data.total)}`]}
                    />

                    <InfoCard title={"Balance"} content={[
                        `$${formatCurrency(data.balance)}`
                    ]}
                    />

                    <InfoCard
                        title={"Factuaras emitidas"}
                        content={[`$${formatCurrency(data.invoicesTotal)}`]}
                    />


                    <InfoCard
                        title={"Facturas pagadas"}
                        content={[`$${formatCurrency(data.paidInvoices)}`]}
                    />


                    <InfoCard
                        title={"Facturas pendientes"}
                        content={[`$${formatCurrency(data.pendingInvoices)}`]}
                    />


                </div>

                <div>
                    <h2 className="text-xl font-bold mt-4">Observaciones al Contrato</h2>
                    <div className="flex gap-2">
                        <DataTable
                            columns={[
                                {name: "Familia", selector: (row: any) => row.family},
                                {name: "Glosa", selector: (row: any) => row.name},
                                {name: "Descripción", selector: (row: any) => row.description},
                                {name: "Monto", selector: (row: any) => `$${formatCurrency(row.amount)}`},
                            ]}
                            data={selectedControlSheet.agreements}
                            contextMessage={{
                                singular: 'observación',
                                plural: 'observaciones',
                                message: 'seleccionadas'
                            }}
                            noDataComponent={<div className="my-10">No hay observaciones</div>}
                        />
                    </div>
                </div>

                <div>
                    <h2 className="text-xl font-bold mt-4">Aumentos/Disminuciones al Contrato</h2>
                    <div className="flex gap-2">
                        <DataTable
                            columns={[
                                {name: "Familia", selector: (row: any) => row.family},
                                {name: "Glosa", selector: (row: any) => row.name},
                                {name: "Descripción", selector: (row: any) => row.description},
                                {name: "Monto real", selector: (row: any) => `$${formatCurrency(row.realAmount)}`},
                                {
                                    name: "Monto recuperable",
                                    selector: (row: any) => `$${formatCurrency(row.recoverAmount)}`
                                },
                            ]}
                            data={selectedControlSheet.agreements}
                            contextMessage={{
                                singular: 'observación',
                                plural: 'observaciones',
                                message: 'seleccionadas'
                            }}
                            noDataComponent={<div className="my-10">No hay aumento/disminuciones</div>}
                        />
                    </div>
                </div>

                <div>
                    <h2 className="text-xl font-bold mt-4">Facturas: ${formatCurrency(data.invoicesTotal)}</h2>
                    <div className="flex gap-2">
                        <DataTable
                            columns={[
                                {
                                    name: "Fecha",
                                    wrap: true,
                                    maxWidth: "10%",
                                    selector: (row: any) => new Date(row.date).toLocaleDateString(),
                                    sortable: true,
                                    sortFunction: (a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime()
                                },
                                {
                                    name: "Familia",
                                    wrap: true,
                                    maxWidth: "10%",
                                    selector: (row: any) => row.family,
                                    sortable: true
                                },
                                {
                                    wrap: true,
                                    maxWidth: "10%",
                                    name: "Factura",
                                    selector: (row: any) => row.rawValue.cabecera.documento.numDocumento
                                },
                                {
                                    wrap: true,
                                    maxWidth: "20%",
                                    name: "Proveedor",
                                    selector: (row: any) => row.client,
                                    sortable: true
                                },
                                {
                                    wrap: true,
                                    maxWidth: "10%",
                                    name: "Descripción",
                                    selector: (row: any) => row.description
                                },
                                {
                                    wrap: true,
                                    maxWidth: "10%",
                                    name: "Monto",
                                    selector: (row: any) => `$${formatCurrency(row.total)}`,
                                    sortable: true,
                                    sortFunction: (a: any, b: any) => parseInt(a.total) - parseInt(b.total)
                                },
                                {wrap: true, maxWidth: "15%", name: "Estado", selector: (row: any) => row.status},
                                {
                                    wrap: true,
                                    maxWidth: "15%",
                                    name: "Estado de pago",
                                    selector: (row: any) => row.paymentStatus
                                },
                            ]}
                            data={data.invoices}
                            contextMessage={{
                                singular: 'observación',
                                plural: 'observaciones',
                                message: 'seleccionadas'
                            }}
                            noDataComponent={<div className="my-10">No hay facturas</div>}
                        />
                    </div>
                </div>

                <div>
                    <h2 className="text-xl font-bold mt-4">Notas de crédito: ${formatCurrency(data.NNCCsTotal)}</h2>
                    <div className="flex gap-2">
                        <DataTable
                            columns={[
                                {
                                    name: "Fecha",
                                    selector: (row: any) => new Date(row.date).toLocaleDateString(),
                                    sortable: true,
                                    sortFunction: (a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime()
                                },
                                {name: "Familia", selector: (row: any) => row.family || "-", sortable: true},
                                {name: "Factura", selector: (row: any) => row.rawValue.factAsociada || "-"},
                                {name: "Proveedor", selector: (row: any) => row.client, sortable: true},
                                // { name: "Descripción", selector: (row: any) => row.description },
                                {
                                    name: "Monto",
                                    selector: (row: any) => `$${formatCurrency(row.total)}`,
                                    sortable: true,
                                    sortFunction: (a: any, b: any) => parseInt(a.total) - parseInt(b.total)
                                },
                                {name: "Estado", selector: (row: any) => row.status},
                                // { name: "Estado de pago", selector: (row: any) => row.paymentStatus },
                            ]}
                            data={data.NNCCs}
                            contextMessage={{
                                singular: 'observación',
                                plural: 'observaciones',
                                message: 'seleccionadas'
                            }}
                            noDataComponent={<div className="my-10">No hay notas de crédito</div>}
                        />
                    </div>
                </div>

                <div>
                    <h2 className="text-xl font-bold mt-4">Orden de compra: ${formatCurrency(data.OCsTotal)}</h2>
                    <div className="flex gap-2">
                        <DataTable
                            columns={[
                                { wrap: true, maxWidth: "10%", name: "Fecha", selector: (row: any) => new Date(row.date).toLocaleDateString(), sortable: true, sortFunction: (a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime() },
                                { wrap: true, maxWidth: "10%", name: "Familia", selector: (row: any) => row.family, sortable: true },
                                { wrap: true, maxWidth: "10%", name: "O/C", selector: (row: any) => row.rawValue.cabecera.documento.numOc },
                                { wrap: true, maxWidth: "15%", name: "Proveedor", selector: (row: any) => row.client, sortable: true },
                                { wrap: true, maxWidth: "20%", name: "Descripción", selector: (row: any) => row.description },
                                { wrap: true, maxWidth: "10%", name: "Monto", selector: (row: any) => `$${formatCurrency(row.total)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.total) - parseInt(b.total) },
                                { wrap: true, maxWidth: "10%", name: "Estado", selector: (row: any) => row.status },
                            ]}
                            data={data.OCs}
                            contextMessage={{
                                singular: 'orden de compra',
                                plural: 'ordenes de compra',
                                message: 'seleccionadas'
                            }}
                            noDataComponent={<div className="my-10">No hay notas de crédito</div>}
                        />
                    </div>
                </div>
            </div>
            }
        </div>
        </Layout>
    );
}

export default ControlSheetPage;