import React, {useContext, useEffect, useState} from 'react';
import {AppContext} from "../Context/MainContext";

interface NavbarProps {
    setSidebarOpen: (value: boolean) => void;
}

const Navbar = ({setSidebarOpen}: NavbarProps) => {
    const [isAlertOpen, setIsAlertOpen] = useState(false); // State for alerts
    const [isAvatarMenuOpen, setIsAvatarMenuOpen] = useState(false); // State for avatar menu

    const {user, selectedProject, setSelectedProject, projects} = useContext(AppContext);

    return (
        <header className="flex items-center justify-between px-6 py-4 bg-white border-b-4 border-indigo-600">
            <div className="flex items-center">
                {/* Menu button for mobile screens */}
                <button onClick={() => setSidebarOpen(true)} className="text-gray-500 focus:outline-none lg:hidden">
                    <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 6H20M4 12H20M4 18H11" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                              strokeLinejoin="round"/>
                    </svg>
                </button>

            </div>

            {/* Notification and User Avatar section */}
            <div className="flex items-center">
                {/* Notification icon */}
                <div className="relative">
                    <button
                        className="flex mx-4 text-gray-600 focus:outline-none"
                        onClick={() => setIsAlertOpen((prev) => !prev)} // Toggle alerts menu
                    >
                        <svg className="w-6 h-6 mr-2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" mirror-in-rtl="true" fill="#000000">
                            <g id="SVGRepo_bgCarrier" ></g>
                            <g id="SVGRepo_tracerCarrier"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path fill="#494c4e"
                                      d="M8 6H5c-.553 0-1-.448-1-1s.447-1 1-1h3c.553 0 1 .448 1 1s-.447 1-1 1zM13 10H5c-.553 0-1-.448-1-1s.447-1 1-1h8c.553 0 1 .448 1 1s-.447 1-1 1zM13 14H5c-.553 0-1-.448-1-1s.447-1 1-1h8c.553 0 1 .448 1 1s-.447 1-1 1z"></path>
                                <path fill="#494c4e"
                                      d="M18 2v8c0 .55-.45 1-1 1s-1-.45-1-1V2.5c0-.28-.22-.5-.5-.5h-13c-.28 0-.5.22-.5.5v19c0 .28.22.5.5.5h13c.28 0 .5-.22.5-.5V21c0-.55.45-1 1-1s1 .45 1 1v1c0 1.1-.9 2-2 2H2c-1.1 0-2-.9-2-2V2C0 .9.9 0 2 0h14c1.1 0 2 .9 2 2z"></path>
                                <path fill="#494c4e"
                                      d="M23.87 11.882c.31.54.045 1.273-.595 1.643l-9.65 5.57c-.084.05-.176.086-.265.11l-2.656.66c-.37.092-.72-.035-.88-.314-.162-.278-.09-.65.17-.913l1.907-1.958c.063-.072.137-.123.214-.167.004-.01.012-.015.012-.015l9.65-5.57c.64-.37 1.408-.234 1.72.305l.374.65z"></path>
                            </g>
                        </svg>
                        { selectedProject ? selectedProject.name : 'Seleccionar Proyecto' }
                    </button>

                    {/* Alerts dropdown menu */}
                    {isAlertOpen && (
                        <div className="absolute right-0 z-20 w-52 py-2 mt-2 bg-white rounded-md shadow-xl">
                            {projects.map((project: any) => (
                                <button key={project._id} onClick={() => {
                                    setSelectedProject(project)
                                    setIsAlertOpen(false)
                                }}
                                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white">
                                    <div className="h-6 flex align-top justify-start">
                                        <span className="mr-2">
                                            {selectedProject?._id === project._id && (
                                                <svg className="w-4 h-6 " viewBox="0 0 24 24" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M5 13l4 4L19 7"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            )}
                                        </span>
                                        <span>
                                            {project.name}
                                        </span>

                                    </div>

                                </button>
                            ))}
                        </div>
                    )}
                </div>

                {/* User Avatar */}

                <div className="relative">
                    <button
                        onClick={() => setIsAvatarMenuOpen((prev) => !prev)} // Toggle avatar menu
                        className="relative block h-8 overflow-hidden shadow focus:outline-none"
                    >
                        <div className="mx-2">
                            <p>{user ? user.name: ""}</p>
                        </div>
                    </button>
                    {/* Avatar dropdown menu */}
                    {false && isAvatarMenuOpen && (
                        <div className="absolute right-0 z-20 w-48 py-2 mt-2 bg-white rounded-md shadow-xl">
                            <a href="#"
                               className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white">
                                Profile
                            </a>
                            <a href="#"
                               className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white">
                                Settings
                            </a>
                            <a href="#"
                               className="block px-4 py-2 text-sm text-gray-700 hover:bg-indigo-600 hover:text-white">
                                Logout
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Navbar;