import React from "react";


const TextFilter: React.FC<{ filterText: string, onFilter: (text: string) => void, onClear: () => void }>  = ({ filterText, onFilter, onClear }) => {

    return (
        <div className="flex items-center">
            <input
                value={filterText}
                onChange={(e) => onFilter(e.target.value)}
                placeholder="Buscar..."
                className="border border-gray-300 p-2 rounded-lg w-64"
            />
            <button onClick={onClear} className="bg-gray-300 p-2 rounded-lg ml-2">Limpiar</button>
        </div>
    );
}

export default TextFilter;