import Layout from "../Layout/Layout.component";
import {AppContext} from "../Context/MainContext";
import {useContext, useEffect, useState} from "react";
import DataTable from "react-data-table-component";
import {formatCurrency} from "../utils/number.utils";

const WorkloadReportPage = () => {

    const { selectedProject, planifications } = useContext(AppContext);
    const [data, setData] = useState<any>({});
    const [columns, setColumns] = useState<any[]>([]);

    useEffect(()=> {
        if (planifications.length > 0) {
            let width = 70 / (selectedProject.workload.length * 2);
            console.log("width", width);
            let colsTmp = selectedProject.workload.map((wk: { name: string, budget: number }, index: number) => {
                return [
                    { name: <div>{wk.name}</div>, wrap: true, selector: (row: any) => `$${formatCurrency(row[wk.name].budget)}`, width: `${width}%` },
                    { name: <div>{`${wk.name} acumulado`}</div>, wrap: true , selector: (row: any) => `$${formatCurrency(row[wk.name].accBudget)}`, width: `${width}%` },
                ]
            }).flat();

            // Create a monthly array of objects where start at the project start date and ends at the project end date
            // Each object will have the following structure:
            // { date: string, [name]: { budget: number, accBudget: number } }
            // Where name is the name of the workload and budget is the budget for that month, it could be multiple budgets naem
            // accBudget is the accumulated budget for that month
            // Planifications are sorted by date and are weekly, so we can iterate over them and add the budget to the corresponding month

            let startDate = new Date(selectedProject.startDate);
            let endDate = new Date(selectedProject.endDate);

            let months = [];
            let month = startDate.getMonth();
            let year = startDate.getFullYear();

            while (year < endDate.getFullYear() || (year === endDate.getFullYear() && month <= endDate.getMonth())) {
                months.push({ month: month, year: year });
                month++;
                if (month > 11) {
                    month = 0;
                    year++;
                }
            }

            let monthlyData = months.map((m: { month: number, year: number }) => {
                let obj: any = { date: `${m.month + 1}-${m.year}` };
                selectedProject.workload.forEach((wk: { name: string, budget: number }) => {
                    obj[wk.name] = { budget: 0, accBudget: 0 };
                });
                return obj;
            });

            planifications.forEach((pl: any) => {
                let date = new Date(pl.date);
                let month = date.getMonth();
                let year = date.getFullYear();
                let monthData = monthlyData.find((m: any) => m.month === month && m.year === year);
                pl.workloadValues.forEach((wl: any) => {
                    monthData[wl.name].budget += wl.budget;
                });
            });

            monthlyData.forEach((m: any, index: number) => {
                if (index > 0) {
                    selectedProject.workload.forEach((wk: { name: string, budget: number }) => {
                        m[wk.name].accBudget = monthlyData[index - 1][wk.name].accBudget + m[wk.name].budget;
                    });
                }
            });

            setData(monthlyData);
            setColumns(colsTmp);
        }
    }, [planifications])

    console.log(data);
    console.log(columns);


    return (
        <Layout>
            <div className="container">
                <h1 className="text-2xl font-bold mb-4">Reporte Mano de Obra</h1>
                {columns.length > 0 && <DataTable
                    columns={[
                        {name: "Fecha", wrap: true, selector: (row: any) => row.date, width: "100px"},
                        ...columns,
                        {name: "Total", wrap: true, selector: (row: any) => `$${formatCurrency(selectedProject.workload.reduce((acc: number, wk: { name: string }) => acc + row[wk.name].budget, 0))}`, width: "75px"},
                        {name: <div>Total acumulado</div>, wrap: true, selector: (row: any) => `$${formatCurrency(selectedProject.workload.reduce((acc: number, wk: { name: string }) => acc + row[wk.name].accBudget, 0))}`, width: "100px"},
                        // {name: "", wrap: true, selector: (row: any) => <button>Ver</button>}
                    ]}
                   data={data}
                />}
            </div>
        </Layout>)
}

export default WorkloadReportPage;