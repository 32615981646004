import {useContext, useEffect, useMemo, useState} from "react";
import {AppContext} from "../Context/MainContext";
import TextFilterComponent from "../Components/Filtering/TextFilter.component";
import {formatCurrency} from "../utils/number.utils";
import Layout from "../Layout/Layout.component";
import DataTable from "react-data-table-component";

const NNCCPage = () => {

    const { transactions} = useContext(AppContext);
    const [data, setData] = useState<any[]>([]);
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data.filter(item => item.client && item.client.toLowerCase().includes(filterText.toLowerCase()));
    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };
        return <TextFilterComponent onFilter={value => setFilterText(value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);


    useEffect(() => {
        if (transactions.length > 0) {
            let tmp = transactions.filter((transaction: any) => transaction.type === 'NNCC');
            let invoices = transactions.filter((transaction: any) => transaction.type === 'FACTURA');
            tmp.forEach((nncc: any) => {
                let invoice = invoices.find((invoice: any) => invoice.rawValue.cabecera.documento.numDocumento === nncc.rawValue.factAsociada);
                nncc.invoice = invoice;
            });
            setData(tmp);
        }
    }, [transactions]);

    let columns = [
        { name: "Fecha", selector: (row: any) => new Date(row.date).toLocaleDateString(), sortable: true, sortFunction: (a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime() },
        { name: "Familia", selector: (row: any) => row.family || "-", sortable: true },
        { name: "Hoja de control", selector: (row: any) => row.controlSheet ? row.controlSheet.name : "-", sortable: true },
        { name: "Factura", selector: (row: any) => row.rawValue.factAsociada || "-" },
        { name: "Proveedor", selector: (row: any) => row.client, sortable: true },
        // { name: "Descripción", selector: (row: any) => row.description },
        { name: "Monto", selector: (row: any) => `$${formatCurrency(row.total)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.total) - parseInt(b.total) },
        { name: "Estado", selector: (row: any) => row.status },
        // { name: "Estado de pago", selector: (row: any) => row.paymentStatus },
    ];

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };


    return (<Layout>
            <div className="container">
                <h1 className="text-2xl font-bold mb-4">Notas de crédito</h1>
                <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination={true}
                    paginationComponentOptions={paginationComponentOptions}
                    defaultSortFieldId={1}
                    defaultSortAsc={false}
                    subHeader
                    subHeaderComponent={subHeaderComponentMemo}
                    persistTableHead
                />
            </div>
        </Layout>

    );
}

export default NNCCPage;