import {createContext, useState} from "react";
import ClientAxios from "../Client.axios";
import {PAGE_STATUS} from "../interfaces";

export const AppContext = createContext<any>({});

export const AppProvider = ({children}: any) => {

    const [user, __setUser] = useState<any | null>(null);
    const [menuSelected, setMenuSelected] = useState<string | null>(null);

    const [projects, setProjects] = useState<any[]>([]);
    const [selectedProject, __setSelectedProject] = useState<any | null>(null);
    const [planifications, setPlanifications] = useState<any[]>([]);
    const [controlSheets, setControlSheets] = useState<any[]>([]);
    const [transactions, setTransactions] = useState<any[]>([]);

    const setSelectedProject = async (project: any) => {
        console.log("Setting project", project);
        try {
            const client = ClientAxios.getInstance();
            let planifications = await client.getPlanifications(project._id);
            let controlSheets = await client.getControlSheets(project._id);
            let transactions = await client.getTransactions(project._id);
            __setSelectedProject(project);
            setPlanifications(planifications.data);
            setControlSheets(controlSheets.data);
            setTransactions(transactions.data);
        } catch (e) {
            console.log(e);
        }
    }

    const setUser = (user: any) => {
        console.log("Setting user", user);
        sessionStorage.setItem('user', JSON.stringify(user));
        __setUser(user);

        let HTTPclient = ClientAxios.getInstance();
        HTTPclient.getProjects().then((response) => {
            setProjects(response.data);
            if (response.data.length === 1) {
                setSelectedProject(response.data[0]);
            }
        });
    }

    return (
        <AppContext.Provider value={{
            user, setUser,
            menuSelected, setMenuSelected,
            projects, setProjects,
            selectedProject, setSelectedProject,
            planifications, setPlanifications,
            controlSheets, setControlSheets,
            transactions, setTransactions
        }}>
            {children}
        </AppContext.Provider>
    )
}