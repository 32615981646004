import {useState} from "react";
import ClientAxios from "../Client.axios";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";

const LoginPage = () => {

    const [data, setData] = useState({
        name: "",
        email: "",
        password: ""
    });

    const navigate = useNavigate();

    const register = async (e: React.FormEvent) => {
        e.preventDefault();

        const client = ClientAxios.getInstance();
        let response = await client.register(data.name, data.email, data.password);
        if (response.error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: response.error
            });
        } else {
            await Swal.fire({
                icon: 'success',
                title: 'Exito',
                text: 'Usuario registrado correctamente'
            });
            navigate("/");
        }
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-500">
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
                {/* Logo */}
                <div className="flex justify-center mb-6">
                    <img src="/Syncroniza_transparente.png" alt="Logo" className="w-24 h-24"/>
                </div>

                {/* Formulario de inicio de sesión */}
                <form onSubmit={register}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="name">
                            Nombre completo
                        </label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Juan Pablo Perez Soto"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            onChange={(e) => setData({...data, name: e.target.value})}
                            value={data.name}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                            Correo Electrónico
                        </label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="correo@ejemplo.com"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            onChange={(e) => setData({...data, email: e.target.value})}
                            value={data.email}
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                            Contraseña
                        </label>
                        <input
                            type="password"
                            id="password"
                            placeholder="********"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                            name={"password"}
                            onChange={(e) => setData({...data, password: e.target.value})}
                            value={data.password}
                        />
                    </div>
                    <div className="flex items-center justify-between">
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            Registrarse
                        </button>
                    </div>
                </form>
            </div>
        </div>);
}

export default LoginPage;